import Link from 'next/link'
import router from 'next/router'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ArrowUpRightIcon from 'assets/svg/app/arrow-up-right-tg.svg'
import CaretDownGrayIcon from 'assets/svg/app/caret-down-gray-slim.svg'
import { DesktopOnlyView, MobileOrTabletView } from 'components/Media'
import { Body } from 'components/Text'
import { FlexDivRow, FlexDivRowCentered } from 'components/layout/flex'
import { EXTERNAL_LINKS } from 'constants/links'
import ROUTES from 'constants/routes'
import { MarketTabs } from 'sections/dashboard/Markets/MarketsTab'
import RefButton from 'sections/shared/components/RefButton'
import media from 'styles/media'

import MobileUserMenu from '../AppLayout/Header/MobileUserMenu'
import Logo from '../Logo'

import { useMarketPageRoute } from 'hooks/useMarketPageRoute'
import MarketsMarquee from './MarketsMarquee'

export type TPages = 'landing-page' | 'stats-page'

const Header = memo(() => {
	const { t } = useTranslation()
	const marketPageRoute = useMarketPageRoute()

	const LINKS = useMemo(
		() => [
			{
				id: 'market',
				label: t('homepage.nav.markets'),
				onClick: () => router.push(ROUTES.Dashboard.Markets(MarketTabs.ALL_MARKETS)),
			},
			{
				id: 'stats',
				label: t('homepage.nav.stats'),
				onClick: () => router.push(ROUTES.Stats.Home),
			},
			{
				id: 'governance',
				label: t('homepage.nav.governance.title'),
				icon: <CaretDownGrayIcon />,
			},
			{
				id: 'learn-more',
				label: t('homepage.nav.learn-more'),
				icon: <ArrowUpRightIcon />,
				onClick: () => window.open(EXTERNAL_LINKS.Docs.DocsRoot, '_blank'),
			},
		],
		[t]
	)

	const GOVERNANCE = [
		{
			id: 'overview',
			label: t('homepage.nav.governance.overview'),
			onClick: () => window.open(EXTERNAL_LINKS.Docs.Governance, '_blank'),
		},
		{
			id: 'kips',
			label: t('homepage.nav.governance.kips'),
			onClick: () => window.open(EXTERNAL_LINKS.Governance.Kips, '_blank'),
		},
		{
			id: 'votes',
			label: t('homepage.nav.governance.votes'),
			onClick: () => window.open(EXTERNAL_LINKS.Governance.Vote, '_blank'),
		},
	]

	return (
		<>
			<MainWrapper>
				<Container>
					<LogoContainer onClick={() => router.push(ROUTES.Home.Root)}>
						<Logo />
					</LogoContainer>
					<DesktopOnlyView>
						<Links>
							{LINKS.map(({ id, label, icon, onClick }) => (
								<StyledTextButton key={id} className={id} onClick={onClick}>
									<FlexDivRowCentered>
										{label}
										{icon}
									</FlexDivRowCentered>
									<StyledMenu className="governance">
										{GOVERNANCE.map(({ id, label, onClick }) => (
											<StyledMenuItem key={id} onClick={onClick}>
												{label}
											</StyledMenuItem>
										))}
									</StyledMenu>
								</StyledTextButton>
							))}
							<Link href={marketPageRoute()}>
								<RefButton noOutline size="small">
									{t('homepage.nav.start-trade')}
								</RefButton>
							</Link>
						</Links>
					</DesktopOnlyView>
				</Container>
				<MarketsMarquee />
			</MainWrapper>
			<MobileOrTabletView>
				<MobileContainer>
					<MobileUserMenu />
				</MobileContainer>
			</MobileOrTabletView>
		</>
	)
})

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	${media.lessThan('lg')`
		flex-direction: row;
		border-bottom: ${(props) => props.theme.colors.selectedTheme.landing.border};
  `}
`

const MobileContainer = styled(FlexDivRow)`
	justify-content: center;
	align-items: center;
`

const StyledMenu = styled.div`
	position: absolute;
	background: ${(props) => props.theme.colors.selectedTheme.newTheme.containers.primary.background};
	border: 1px solid rgba(255, 255, 255, 0.1);
	z-index: 10;
	border-radius: 15px;
	max-width: 150px;
	margin: auto;
	margin-top: 35px;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;

	&.governance {
		visibility: hidden;
		transition: visibility 0.1s;
		:hover {
			visibility: visible;
		}
	}

	&.socials {
		visibility: hidden;
		transition: visibility 0.1s;
		:hover {
			visibility: visible;
		}
	}

	& > p {
		padding: 20px 20px;
		cursor: pointer;
		border-bottom: 1px solid ${(props) => props.theme.colors.selectedTheme.newTheme.border.color};
	}

	& > p:last-child {
		border-bottom: none;
	}
`

const StyledMenuItem = styled(Body).attrs({ weight: 'bold' })`
	cursor: pointer;
	width: 100%;
	font-size: 15px;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding-top: 0px;
	padding-bottom: 0px;
	margin: 0px;
	color: ${(props) => props.theme.colors.selectedTheme.white};

	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.newTheme.border.color}66;
	}
	svg {
		margin-right: 10px;
		width: 15px;
		height: 15px;
	}
`

const Container = styled.header`
	height: 60px;
	width: 100%;
	display: flex;
	padding: 10px 40px;
	${media.lessThan('lg')`
			flex: 1;
			padding: 10px 10px;
			padding-left: 20px;
			`}
`

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	& span {
		width: 150px;
	}
	${media.lessThan('lg')`
		border-right: ${(props) => props.theme.colors.selectedTheme.landing.border};
		& span {
			width: 120px;
		}
  	`}
`

const Links = styled.div`
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	align-items: center;
	justify-content: end;
	width: 100%;
	z-index: 100;
`

const StyledTextButton = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 14px;
	line-height: 15px;
	font-weight: 400;
	color: ${(props) => props.theme.colors.common.primaryWhite};
	cursor: pointer;
	padding: 8px 10px;
	border-radius: 100px;
	margin-right: 10px;

	&:hover {
		background: #252525;
		color: ${(props) => props.theme.colors.selectedTheme.white};
	}

	&.governance:hover {
		> div.governance {
			visibility: visible;
		}
	}

	&.socials:hover {
		> div.socials {
			visibility: visible;
		}
	}

	svg {
		margin-left: 5px;
	}
`

export default Header
